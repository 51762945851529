import { CDN_URL } from '../../config';

const advisors = [
    {
        name: 'David Antonioli',
        designation: 'Former Principal CEO of Verra',
        linkedinProfile: ' https://www.linkedin.com/in/david-antonioli/',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'antonioli.png',
        desc: '',
    },
];

export default advisors;
